<template>
  <v-card>
    <v-tabs>
      <v-tab @click="tabs = 0">Relatórios</v-tab>
      <v-tab @click="tabs = 1">Rascunhos</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-card class="ma-3">
          <DateInputsFraude
            v-if="tabs === 0"
            ref="dataInputRelatorio"
            v-model="relatorios"
            @searching="loading = $event"
            @filter="filter"
          />

          <v-dialog
            v-model="showRelatorio"
            style="hidden"
            scrollable
            hide-overlay
          >
            <v-card>
              <v-toolbar color="primary" dark>
                <v-col cols="11.5">
                  <span class="headline">Relatório de fraudes</span>
                </v-col>
                <v-btn icon dark @click="showRelatorio = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text outlined>
                <div>
                  <RelatorioViewFraude
                    v-if="showRelatorio"
                    :cod-relatorio="codRelatorio"
                  />
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn color="error" @click="showRelatorio = false">
                  <v-icon dark left> mdi-minus-circle </v-icon>
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="showStatus"
            style="hidden"
            width="1000px"
            scrollable
            hide-overlay
          >
            <v-card>
              <v-toolbar color="primary" dark>
                <v-col cols="11">
                  <span style="font-size: 18px"> Histórico de Validação </span>
                </v-col>
                <v-col cols="1">
                  <v-btn icon dark @click="showStatus = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-toolbar>
              <div>
                <StatusView
                  v-if="showStatus"
                  :relatorio="relatorios"
                  :cod-relatorio="codRelatorio"
                />
              </div>
              <v-card-actions class="justify-end">
                <v-btn color="error" width="100" @click="showStatus = false">
                  <v-icon dark left> mdi-minus-circle </v-icon>
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <base-loading v-if="loading" />
          <crud-list
            v-else
            v-model="relatorios"
            :headers="headers"
            :actions="actions"
            :slots="[
              'item.DataAtendimento',
              'item.sincronized',
              'item.ValidacaoId',
            ]"
            :search-init="search"
            :search.sync="search"
          >
            <template v-slot:[`item.DataAtendimento`]="{ item }">
              {{ moment(item.DataAtendimento).format('DD/MM/YYYY') }}
            </template>
            <template v-slot:[`item.sincronized`]="{ item }">
              {{ moment(item.sincronized).format('DD/MM/YYYY') }}
            </template>
            <template v-slot:[`item.ValidacaoId`]="{ item }">
              <v-btn
                rounded
                small
                plain
                text
                :class="
                  item.ValidacaoId === 'Não validado'
                    ? 'light-blue darken-4 white--text'
                    : item.ValidacaoId === 'Válido'
                    ? 'teal lighten-1 white--text'
                    : item.ValidacaoId === 'Inconsistente'
                    ? 'red lighten-1 white--text'
                    : item.ValidacaoId === 'Justificado'
                    ? 'teal darken-4 white--text'
                    : '-'
                "
                @click="updateStatus(item)"
              >
                {{ item.ValidacaoId }}
              </v-btn>
            </template>

            <template v-if="reports.length >= 1" v-slot:print>
              <v-btn
                elevation="2"
                class="ml-3"
                color="primary"
                @click="printAll"
                >Imprimir todos
              </v-btn>
            </template>
          </crud-list>

          <div :key="codRelatorio">
            <RelatorioViewFraude
              v-if="!!codRelatorio"
              ref="exportExternReport"
              :cod-relatorio="codRelatorio"
              style="position: absolute; left: -9999px; top: -9999px"
            />
          </div>

          <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="290">
              <v-card>
                <v-card-title class="text-h3" style="word-break: normal">
                  Aguarde estamos preparando os relatórios!
                </v-card-title>
                <v-card-text>
                  <div class="text-center">
                    <v-progress-circular
                      :rotate="360"
                      :size="100"
                      :width="15"
                      :value="loadPercentage"
                      color="teal"
                    >
                      {{ loadPercentage.toPrecision(3) }}%
                    </v-progress-circular>
                  </div></v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red darken-1"
                    text
                    @click="dialog = false && cancelPrint()"
                  >
                    Cancelar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card v-if="tabs === 1" class="ma-3">
          <DateInputsFraude
            v-if="tabs === 1"
            ref="dataInputRelatorio"
            v-model="relatorios"
            :draft="true"
            @searching="loading = $event"
            @filter="filter"
          />

          <v-dialog
            v-model="showRelatorio"
            style="hidden"
            scrollable
            hide-overlay
          >
            <v-card>
              <v-toolbar color="primary" dark>
                <v-col cols="11.5">
                  <span class="headline">Relatório de fraudes</span>
                </v-col>
                <v-btn icon dark @click="showRelatorio = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text outlined>
                <div v-if="tabs === 1">
                  <RelatorioViewFraude
                    v-if="showRelatorio"
                    :draft="true"
                    :cod-relatorio="codRelatorio"
                  />
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn color="error" @click="showRelatorio = false">
                  <v-icon dark left> mdi-minus-circle </v-icon>
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <base-loading v-if="loading" />
          <crud-list
            v-else
            v-model="relatorios"
            :headers="headers"
            :actions="actions"
            :slots="['item.DataAtendimento', 'item.sincronized']"
            :search-init="search"
            :search.sync="search"
          >
            <template v-slot:[`item.DataAtendimento`]="{ item }">
              {{ moment(item.DataAtendimento).format('DD/MM/YYYY') }}
            </template>
            <template v-slot:[`item.sincronized`]="{ item }">
              {{ moment(item.sincronized).format('DD/MM/YYYY') }}
            </template>
            <template v-if="reports.length >= 1" v-slot:print>
              <v-btn
                elevation="2"
                class="ml-3"
                color="primary"
                @click="printAll"
                >Imprimir todos
              </v-btn>
            </template>
          </crud-list>

          <div v-if="tabs === 1" :key="codRelatorio">
            <RelatorioViewFraude
              v-if="!!codRelatorio"
              ref="exportExternReport"
              :cod-relatorio="codRelatorio"
              :draft="true"
              style="position: absolute; left: -9999px; top: -9999px"
            />
          </div>

          <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="290">
              <v-card>
                <v-card-title class="text-h3" style="word-break: normal">
                  Aguarde estamos preparando os relatórios!
                </v-card-title>
                <v-card-text>
                  <div class="text-center">
                    <v-progress-circular
                      :rotate="360"
                      :size="100"
                      :width="15"
                      :value="loadPercentage"
                      color="teal"
                    >
                      {{ loadPercentage.toPrecision(3) }}%
                    </v-progress-circular>
                  </div></v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red darken-1"
                    text
                    @click="dialog = false && cancelPrint()"
                  >
                    Cancelar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { storageReportAvaliable } from '../../../utils/report'
import { createMutiplesReport } from '../../../utils/report/createReport'
import DateInputsFraude from './DateInputsFraude'
import RelatorioViewFraude from './show/RelatorioViewFraude.vue'
import StatusView from './show/StatusView.vue'
export default {
  components: {
    DateInputsFraude,
    RelatorioViewFraude,
    StatusView,
  },
  data() {
    const viewReport = {
      title: 'Visualizar relatório',
      color: 'primary',
      click: item => {
        this.showRelatorio = true
        this.codRelatorio = item.CodRelatorio
      },
      icon: 'mdi-eye',
    }
    const removeReport = {
      title: 'Excluir relatório',
      color: 'red darken-3',
      click: item => this.deleteItem(item),
      icon: 'mdi-delete',
    }
    const selectReport = {
      title: 'checkbox',
      color: 'blue darken-3',
      click: item => this.addReport(item),
    }
    let btnAction = []
    this.$user.get().role === 'admin'
      ? btnAction.push(viewReport, removeReport, selectReport)
      : btnAction.push(viewReport, selectReport)
    return {
      showRelatorio: false,
      codRelatorio: '',
      componentUpdate: 1,
      reports: [],
      dialog: false,
      tabs: 0,
      loadPercentage: 0,
      actions: btnAction,
      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'ValidacaoId',
          width: '120px',
          filterable: true,
        },
        {
          text: 'Consultor',
          align: 'center',
          sortable: true,
          value: 'Consultor',
          width: '200px',
          filterable: true,
        },
        {
          text: 'Produtor',
          align: 'center',
          sortable: true,
          value: 'Produtor',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Fazenda',
          align: 'center',
          sortable: true,
          value: 'Fazenda',
          width: 'auto',
          filterable: true,
        },
        {
          align: 'center',
          sortable: true,
          text: 'Agroindústria',
          value: 'Agroindustria',
          width: 'auto',
          filterable: true,
        },
        {
          align: 'center',
          sortable: true,
          text: 'Número da Visita',
          value: 'NumeroVisita',
          width: '80px',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Data da Visita',
          value: 'DataAtendimento',
          width: 'auto',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Hora Início',
          value: 'HoraInicio',
          width: '20px',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Hora Fim',
          value: 'HoraFim',
          width: '20px',
        },
        this.$user.get().role !== 'gestor'
          ? {
              align: 'center',
              sortable: true,
              text: 'Data da sincronização',
              value: 'sincronized',
              width: '20px',
            }
          : '',
      ],
      loading: true,
      showStatus: false,
      relatorios: [],
      relatoriosData: [],
    }
  },
  computed: {
    ...mapState(['relatorio_search']),
    search: {
      get() {
        return null
      },
      set(val) {
        this.$store.commit('SET_RELATORIO_SEARCH', val)
      },
    },
  },

  mounted() {
    this.$root.$on('updateGrid', () => {
      this.$refs.dataInputRelatorio.getRelatorios()
    })
  },

  methods: {
    storageReportAvaliable,
    filter(data) {
      if (this.relatoriosData.length <= 0) this.relatoriosData = this.relatorios

      let res = this.relatoriosData

      if (data.agroindustria) {
        res = res.filter(item => item.Agroindustria === data.agroindustria.name)
      }

      if (data.produtor) {
        res = res.filter(item => item.Produtor === data.produtor.name)
      }

      if (data.consultor) {
        res = res.filter(item => item.Consultor === data.consultor.Nome)
      }

      this.relatorios = res
    },

    updateStatus(item) {
      this.codRelatorio = item.CodRelatorio
      this.showStatus = true
    },

    cancelPrint() {
      this.codRelatorio = ''
      this.relatorios.forEach(item => {
        item.checked = false
      })
      this.reports = []
    },

    async printAll() {
      await createMutiplesReport(this)
    },

    addReport(item) {
      const find = this.reports.find(
        report => report.CodRelatorio === item.CodRelatorio,
      )

      if (!find) this.reports.push(item)
      else
        this.reports = this.reports.filter(
          report => report.CodRelatorio !== item.CodRelatorio,
        )
    },

    deleteItem(item) {
      if (this.$user.get().role === 'admin') {
        this.Swal.fire({
          title: 'Apagar Relatório',
          text: 'Você tem certeza que deseja remover este relatório?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#ff5252',
          cancelButtonColor: '#999',
          confirmButtonText: 'Sim, desejo removê-lo!',
          cancelButtonText: 'Cancelar',
        }).then(result => {
          if (result.value) {
            try {
              this.loading = true
              this.api.delete.relatorioccs(item.CodRelatorio).then(response => {
                if (response.success) {
                  this.$refs.dataInputRelatorio.getRelatorios()
                  this.Swal.fire({
                    title: 'Removido!',
                    text: 'Este relatório foi removido com sucesso!',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                  })
                } else {
                  this.Swal.fire({
                    title: 'Ops!',
                    text: 'Não foi possível apagar este relatório!',
                    icon: 'danger',
                    showCancelButton: false,
                    confirmButtonColor: '#00bf60',
                    confirmButtonText: 'Ok',
                  })
                }
              })
            } catch (error) {
              this.Swal.fire({
                title: 'Ops!',
                text: 'Houve um erro na comunicação com o servidor!',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#00bf60',
                confirmButtonText: 'Ok',
              })
            } finally {
              this.loading = false
            }
          }
        })
      } else {
        this.Swal.fire({
          title: 'Apagar Relatório',
          text: 'Você não tem permissão para deletar relatórios!',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#00bf60',
          confirmButtonText: 'Ok',
        })
      }
    },
  },
}
</script>
